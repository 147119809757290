import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import { SButton, SIcon } from '@strana-artw/ui-kit';
export default {
  name: 'TopNotification',
  components: {
    SButton: SButton,
    SIcon: SIcon
  },
  props: {
    type: {
      type: String,
      default: 'warning',
      validate: ['information', 'warning'].includes
    },
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    iconsColors: function iconsColors() {
      return {
        iconColor: '#92278F',
        closeIconColor: '#FFFFFF'
      };
    }
  },
  methods: {
    close: function close() {
      this.$emit('close', this.id);
    }
  }
};