import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
import QRCodeModal from '@@/common/components/common/QRCode/QRCodeModal.vue';
import _debounce from 'lodash/debounce';
import { storeToRefs } from 'pinia';
import { SIcon } from '@strana-artw/ui-kit';
import HeaderQRCodePopup from '@/components/layout/header/HeaderQRCodePopup.vue';
import { useOnboardingStore } from '@/store/onboarding';
var QR_CODE_SLUG = 'qr_code';
export default {
  name: 'HeaderQRCode',
  components: {
    QRCodeModal: QRCodeModal,
    HeaderQRCodePopup: HeaderQRCodePopup,
    SIcon: SIcon
  },
  props: {
    qrCodeData: {
      required: true,
      type: Object
    }
  },
  setup: function setup() {
    var onboardingStore = useOnboardingStore();
    var confirmNotification = onboardingStore.confirmNotification;
    var _storeToRefs = storeToRefs(onboardingStore),
      notifications = _storeToRefs.notifications;
    return {
      notifications: notifications,
      confirmNotification: confirmNotification
    };
  },
  data: function data() {
    return {
      isQRModalOpen: false,
      isPopupOpen: false,
      isMounted: false,
      buttonRect: null,
      intervalId: null
    };
  },
  computed: {
    qrCodeNotification: function qrCodeNotification() {
      var _this$notifications;
      return (_this$notifications = this.notifications) === null || _this$notifications === void 0 ? void 0 : _this$notifications.find(function (item) {
        return item.slug === QR_CODE_SLUG;
      });
    }
  },
  watch: {
    qrCodeNotification: function qrCodeNotification(newValue) {
      if (!this.isMounted) {
        return;
      }
      if (newValue && !this.isPopupOpen) {
        this.openPopup();
      }
    },
    isMounted: function isMounted(value) {
      if (value && this.qrCodeNotification) {
        this.openPopup();
      }
    },
    isPopupOpen: function isPopupOpen(value) {
      if (!value) {
        clearInterval(this.intervalId);
      }
    }
  },
  mounted: function mounted() {
    window.addEventListener('resize', this.debouncedUpdatePopupPosition);
    this.isMounted = true;
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.debouncedUpdatePopupPosition);
  },
  methods: {
    handleQRButtonClick: function handleQRButtonClick() {
      if (this.isPopupOpen) {
        this.$refs.popup.close();
        return;
      }
      this.isQRModalOpen = true;
    },
    openPopup: function openPopup() {
      var _this = this;
      this.$nextTick(function () {
        _this.updatePopupPosition();
        _this.isPopupOpen = true;
        clearInterval(_this.intervalId);
        _this.intervalId = setInterval(function () {
          if (_this.$refs.QRButton) {
            _this.updatePopupPosition();
          }
        }, 300);
      });
    },
    handlePopupClick: function handlePopupClick() {
      this.isPopupOpen = false;
      if (this.qrCodeNotification) {
        this.confirmNotification(this.qrCodeNotification.id);
      }
    },
    updatePopupPosition: function updatePopupPosition() {
      var _this$$refs$QRButton$ = this.$refs.QRButton.getBoundingClientRect(),
        top = _this$$refs$QRButton$.top,
        left = _this$$refs$QRButton$.left,
        height = _this$$refs$QRButton$.height,
        width = _this$$refs$QRButton$.width;
      this.buttonRect = {
        top: top,
        left: left,
        height: height,
        width: width
      };
    },
    debouncedUpdatePopupPosition: _debounce(function () {
      this.updatePopupPosition();
    }, 50)
  }
};