import "core-js/modules/es.array.includes.js";
export default {
  name: 'IconLandingPartnerSliderArrow',
  props: {
    direction: {
      type: String,
      default: 'left',
      validator: function validator(value) {
        return ['left', 'right'].includes(value);
      }
    }
  }
};