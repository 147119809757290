import { goTo } from '@@/common/modules/goto/goTo';
import { useMemoize } from '@vueuse/core';

const isPrefersReducedMotion = useMemoize(() => typeof window === 'undefined' || window.matchMedia('(prefers-reduced-motion: reduce)').matches);

function getDefaultResult () {
  return {
    x: 0,
    y: 0,
    behavior: isPrefersReducedMotion() ? 'auto' : 'smooth'
  };
}

export default async function (to, from, savedPosition) {
  const defaultResult = getDefaultResult();

  if (to.meta.disableScrollOnRouteChange) {
    // Валидное значение, если прокрутка не нужна →
    // https://v3.router.vuejs.org/ru/guide/advanced/scroll-behavior.html
    // когда торчало return; (т.е. undefined), то косячило в каких-то сценариях (например, в /profile)
    return {};
  }

  if (to.hash) {
    let positionY = 0;

    if (isPrefersReducedMotion()) {
      positionY = document.getElementById(to.hash)?.scrollTop ?? 0;
    } else {
      positionY = await goTo(to.hash, {
        duration: 1000
      });
    }

    return {
      ...defaultResult,
      y: positionY
    };
  } else {
    return {
      ...defaultResult,
      ...(savedPosition ?? {})
    };
  }
}
