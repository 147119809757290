import { useAuthStore } from '@@/lk_broker/store/authStore';
import {
  tryGetAllKeys,
  tryGetFromStorage,
  tryRemoveFromStorage,
  trySaveToStorage
} from '@@/shared/lib/utils/storageUtils';
import { useIsomorphicRoute } from '@@/lk_broker/plugins/app-context';
import type { CityResponse } from '@@/lk_broker/types/Cities';
import { NonStrictStringLiteral } from '@@/lk_broker/types/utilities/misc';

const cityOfSaleLocalStorageKey = 'filter_cityOfSale_stored-value';

function isCity (arg: unknown): arg is CityResponse {
  return typeof arg === 'object' &&
    arg != null &&
    'globalId' in arg &&
    'slug' in arg;
}

function isCitiesArray (arg: unknown): arg is Array<CityResponse> {
  if (!Array.isArray(arg)) {
    return false;
  }

  return arg.length === 0 || isCity(arg[0]);
}

type UniqueKeys = 'choice-apartment'
type NonStrictKey = NonStrictStringLiteral<UniqueKeys>

function concatKey (key?: NonStrictKey) {
  return key ? `${ cityOfSaleLocalStorageKey }_${ key }` : cityOfSaleLocalStorageKey;
}

let authStore: ReturnType<typeof useAuthStore> | undefined;
function getCityFromUser () {
  try {
    if (!authStore) {
      authStore = useAuthStore();
    }

    return authStore.user?.city_of_sale;
  } catch {}

  return undefined;
}

function getCurrentPath () {
  try {
    const route = useIsomorphicRoute();

    return route.value.name ?? route.value.path;
  } catch {}

  return undefined;
}

/**
 * Получает "город продаж" - если есть в localStorage ("кэшированное" значение), то оттуда, а если нет - то из профиля
 * @param key необязательный параметр - ключ, если необходимо синхронизировать "кэширование" на разных страницах
 */
export function getCityOfSale (key?: NonStrictKey): CityResponse | undefined {
  const resultKey = key ?? getCurrentPath();
  const fromLocal = tryGetFromStorage('localStorage', concatKey(resultKey));

  try {
    if (fromLocal.success && fromLocal.value) {
      const parsed = JSON.parse(fromLocal.value);

      if (isCity(parsed)) {
        return parsed;
      }
    }
  } catch { }

  return getCityFromUser();
}

/**
 * Аналог {@link getCityOfSale} для календаря (там множественный выбор)
 */
export function getManyCitiesOfSale (key?: NonStrictKey): Array<CityResponse> | undefined {
  const resultKey = key ?? getCurrentPath();
  const fromLocal = tryGetFromStorage('localStorage', concatKey(resultKey));

  try {
    if (fromLocal.success && fromLocal.value) {
      const parsed = JSON.parse(fromLocal.value);

      if (isCitiesArray(parsed) && parsed.length) {
        return parsed;
      } else if (isCity(parsed)) {
        return [parsed];
      }
    }
  } catch {}

  const fromProfile = getCityFromUser();

  return fromProfile ? [fromProfile] : [];
}

/**
 * Сохраняет в localStorage "кэшированное" значение "города продаж"
 * @param city город для сохранения
 * @param key необязательный параметр - ключ, если необходимо синхронизировать "кэширование" на разных страницах
 */
export function saveCityOfSale (city: CityResponse, key?: NonStrictKey) {
  const resultKey = key ?? getCurrentPath();
  trySaveToStorage('localStorage', concatKey(resultKey), JSON.stringify(city));
}

/**
 * Аналог {@link saveCityOfSale} для календаря (там множественный выбор)
 */
export function saveManyCitiesOfSale (city: Array<CityResponse>, key?: NonStrictKey) {
  const resultKey = key ?? getCurrentPath();
  trySaveToStorage('localStorage', concatKey(resultKey), JSON.stringify(city));
}

/**
 * Очищает localStorage от "кэша" "города продаж"
 */
export function clearCityOfSale () {
  const result = tryGetAllKeys('localStorage');

  if (!result.success) {
    return;
  }

  for (const key of result.keys) {
    if (key.startsWith(cityOfSaleLocalStorageKey)) {
      tryRemoveFromStorage('localStorage', key);
    }
  }
}
