import "core-js/modules/es.array.includes.js";
export default {
  name: 'IconCrown',
  props: {
    level: {
      type: String,
      default: 'silver',
      validator: function validator(value) {
        return ['gold', 'platinum', 'silver'].includes(value);
      }
    }
  },
  computed: {
    isGold: function isGold() {
      return this.level === 'gold';
    },
    isPlatinum: function isPlatinum() {
      return this.level === 'platinum';
    }
  }
};