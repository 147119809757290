import { SButton, SIcon } from '@strana-artw/ui-kit';
import { DateOutput } from '@strana-artw/cabinets-common';
import QRCodeCanvas from '@@/common/components/common/QRCode/QRCodeCanvas.vue';
import BaseModal from '@@/common/components/common/BaseModal.vue';
export default {
  name: 'QRCodeModal',
  components: {
    DateOutput: DateOutput,
    QRCodeCanvas: QRCodeCanvas,
    BaseModal: BaseModal,
    SButton: SButton,
    SIcon: SIcon
  },
  model: {
    prop: 'open',
    event: 'update:open'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    qrCodeData: {
      required: true,
      type: Object
    }
  },
  computed: {
    isOpen: {
      get: function get() {
        return this.open;
      },
      set: function set(value) {
        this.$emit('update:open', value);
      }
    },
    dateFormatOptions: function dateFormatOptions() {
      return {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      };
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.$emit('update:open', false);
    }
  }
};