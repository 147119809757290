import {
  defineStore,
  storeToRefs
} from 'pinia';
import {
  useFetch
} from '@nuxtjs/composition-api';
import { ref } from 'vue';
import axios from 'axios';
import {
  useIsomorphicContext,
  useVuexStore
} from '@/plugins/app-context';
import { useSentry } from '@/plugins/sentry';
import {
  FLAGS,
  useFeatureFlagsStore
} from '@/store/featureFlags';
import { useCitiesStore } from '@/store/cities';

export interface GetManagerResponseItem {
  email: string;
  name: string;
  lastname: string;
  patronymic: string;
  position: string;
  phone: string;
  // eslint-disable-next-line camelcase
  work_schedule: string;
  photo: {
    s3: string;
    aws: string;
    src: string;
  };
  city: {
    value: string;
    label: string;
  };
}
interface GetManagerResponse {
  result: Array<GetManagerResponseItem>;
}

export const useHelpManager = defineStore('help-manager', () => {
  const defaultCity = 'tmn';
  const manager = ref<GetManagerResponseItem | undefined>(undefined);
  const managers = ref<Array<GetManagerResponseItem>>([]);
  // FIXME $auth и прочий стор на pinia
  const getUserCityKey = (): string | undefined => useVuexStore().$auth?.$state?.user?.agency?.city as string | undefined;

  async function getUserCity () {
    const featureFlags = useFeatureFlagsStore();

    if (featureFlags.isEnabledFlag(FLAGS.cityOfSale)) {
      return useVuexStore().$auth?.$state?.user?.city_of_sale.slug;
    }

    const citiesStore = useCitiesStore();
    await citiesStore.getCities(true);

    const { cities } = storeToRefs(useCitiesStore());
    const cityKey = getUserCityKey();

    const city = cities.value.find((city) => {
      return city.text.toLowerCase() === cityKey?.toLowerCase();
    });

    return city?.citySlug || defaultCity;
  }

  async function fetchAllManagers (citySlug: string): Promise<GetManagerResponse> {
    /*
      Не использовать
      const nuxtContext = useIsomorphicContext()
      в script setup на верхнем уровне, т.к. будет ошибка Converting circular structure to JSON
    */
    const nuxtContext = useIsomorphicContext();

    return nuxtContext.$axios.$get<GetManagerResponse>(nuxtContext.$api.account.managers.getManager(citySlug));
  }

  function fetchManager (): GetManagerResponseItem | undefined {
    if (!managers.value?.length) {
      return undefined;
    }
    const randomIndex = Math.floor(Math.random() * managers.value.length);

    return managers.value[randomIndex];
  }

  const isLoading = ref(false);

  useFetch(async () => {
    isLoading.value = true;

    const city = await getUserCity();
    try {
      managers.value = (await fetchAllManagers(city)).result;
      manager.value = fetchManager();
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 404) {
        managers.value = (await fetchAllManagers(defaultCity)).result;
        manager.value = fetchManager();
      } else {
        console.log('🚀 ~ helpManager.ts ~~ fetch ~ error', error);
        useSentry().captureException(error);
      }
    } finally {
      isLoading.value = false;
    }
  });

  return {
    managers,
    manager,
    isLoading
  };
});
