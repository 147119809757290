import { defineStore } from 'pinia';
import {
  computed,
  ref
} from 'vue';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { prepareNextPageUrl } from '@@/shared/utilites/api';
import type {
  DealsFacets,
  DealsFacetsResponseData,
  DealsLookup,
  DealsNextPageArgs,
  DealsOrdering,
  DealsPropertySpecs,
  DealsRequestParams,
  DealsResponseData,
  DealsSpecs,
  DealsSpecsResponseData
} from './types';
import {
  getUserRole
} from './utils';
import { useAxios } from '@/plugins/app-context';
import { Deal } from '@/types/Deal';
import { accountApiConfig } from '@/config/api/account';
import type { PageInfo } from '@/types/PageInfo';

export type DealsList = Array<Deal>
export type PagedDeals = Array<DealsList>

export const useDealsStore = defineStore('deals', () => {
  const pagedDeals = ref<PagedDeals>([]);
  const deals = computed(() => pagedDeals.value.flat(1));
  const count = ref(0);
  const pageInfo = ref<null | PageInfo>(null);
  const specs = ref<null | DealsSpecs>(null);
  const ordering = ref<null | DealsOrdering>(null);
  const lookup = ref<null | DealsLookup>(null);
  const facets = ref<null | DealsFacets>(null);
  const propertySpecs = ref<null | DealsPropertySpecs>(null);

  async function getDeals (params: DealsRequestParams, append = false): Promise<DealsResponseData> {
    const { dependentRole } = accountApiConfig;
    const url = dependentRole.bookings('users', getUserRole());

    // TODO пока сделано так.
    //  Отфильтровываем пустые и NULL значения,
    //  т.к. для бэка это валидное значение и он по ним фильтрует
    //  Надо разобраться почему в search подставляется null.
    //  Из-за этого бэк не правильно фильтрует сделки
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => {
        return value !== '' && value !== null;
      })
    );

    const response = await useAxios().$get<DealsResponseData>(url, { params: filteredParams });

    if (!append) {
      pagedDeals.value.splice(0);
    }

    if (response.result.length) {
      pagedDeals.value.push(response.result);
    }

    count.value = response.count;
    pageInfo.value = response.page_info;

    return response;
  }

  async function getSpecs (): Promise<DealsSpecsResponseData> {
    const { bookings } = accountApiConfig;
    const url = bookings.specsV2(getUserRole());

    const response = await useAxios().$get<DealsSpecsResponseData>(url);
    specs.value = response.specs;
    ordering.value = response.ordering;

    return response;
  }

  async function getPropertySpecs (): Promise<DealsPropertySpecs> {
    const url = 'api/properties/type_specs';
    const result = await useAxios().$get<DealsPropertySpecs>(url);

    propertySpecs.value = [
      {
        label: 'Все',
        value: undefined
      },
      ...result
    ];

    return propertySpecs.value;
  }

  async function getFacets (params: DealsRequestParams): Promise<void> {
    const { bookings } = accountApiConfig;
    const url = bookings.facets(getUserRole());
    const response = await useAxios().$get<DealsFacetsResponseData>(url, { params });
    facets.value = response.facets;
  }

  async function fetchLookup (params: DealsRequestParams): Promise<DealsLookup> {
    const { bookings } = accountApiConfig;
    const url = bookings.lookup(getUserRole());
    lookup.value = await useAxios().$get<DealsLookup>(url, { params });

    return lookup.value;
  }

  async function nextPage ({ page, infiniteState }: DealsNextPageArgs): Promise<void> {
    try {
      if (!page?.next_page) {
        infiniteState.complete();

        return;
      }
      const response = await useAxios().$get<DealsResponseData>(prepareNextPageUrl(page.next_page));

      if (!response.page_info) {
        throw new Error('page info not received');
      }

      if (response.result?.length) {
        pageInfo.value = response.page_info;
        pagedDeals.value.push(response.result);

        if (!pageInfo.value.next_page) {
          infiniteState.complete();
        } else {
          infiniteState.loaded();
        }
      } else {
        infiniteState.loaded();
      }
    } catch (error) {
      infiniteState.complete();
      throw error;
    }
  }

  function dropLookupItems (): void {
    lookup.value = null;
  }

  const decoratedApiActions = decorateApiActions({
    getDeals,
    getSpecs,
    getPropertySpecs,
    getFacets,
    fetchLookup,
    nextPage
  }, 'dealsPinia');

  const decoratedApiActionsRethrow = decorateApiActions({
    getDealsRethrow: getDeals
  }, 'dealsPinia', true);

  return {
    deals,
    pagedDeals,
    count,
    pageInfo,
    specs,
    ordering,
    lookup,
    facets,
    propertySpecs,
    dropLookupItems,
    ...decoratedApiActions,
    ...decoratedApiActionsRethrow
  };
});
