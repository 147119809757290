import { toRefs } from 'vue';
import { useScreenPlugin } from '@/plugins/vue-screen';
export default {
  name: 'CustomTemplateWithLeftBlock',
  props: {
    asideWidth: {
      type: String,
      required: true
    },
    isHeaderStatic: {
      type: Boolean,
      default: false
    },
    maxContentWidth: {
      type: String,
      default: undefined
    }
  },
  setup: function setup() {
    var _toRefs = toRefs(useScreenPlugin()),
      lg = _toRefs.lg;
    return {
      lg: lg
    };
  }
};