import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4332012b = () => interopDefault(import('../lk_broker/components/layout/TheAsideImage' /* webpackChunkName: "@/components/layout/TheAsideImage" */))
const _2a6fd57d = () => interopDefault(import('../lk_broker/pages/account.vue' /* webpackChunkName: "" */))
const _6b8569f4 = () => interopDefault(import('../lk_broker/pages/account/change-password.vue' /* webpackChunkName: "pages/account/change-password" */))
const _5a138306 = () => interopDefault(import('../lk_broker/pages/account/email-confirm.vue' /* webpackChunkName: "pages/account/email-confirm" */))
const _4b1d7e5c = () => interopDefault(import('../lk_broker/pages/account/email-confirmed.vue' /* webpackChunkName: "pages/account/email-confirmed" */))
const _6343231a = () => interopDefault(import('../lk_broker/pages/account/forgot-password.vue' /* webpackChunkName: "pages/account/forgot-password" */))
const _52fd40e1 = () => interopDefault(import('../lk_broker/pages/account/forgot-password-emailed.vue' /* webpackChunkName: "pages/account/forgot-password-emailed" */))
const _17634dd5 = () => interopDefault(import('../lk_broker/pages/account/reglament.vue' /* webpackChunkName: "pages/account/reglament" */))
const _a6040ab0 = () => interopDefault(import('../lk_broker/pages/account/set-password.vue' /* webpackChunkName: "pages/account/set-password" */))
const _e83103dc = () => interopDefault(import('../lk_broker/pages/account/registration/agents.vue' /* webpackChunkName: "pages/account/registration/agents" */))
const _bec9cb56 = () => interopDefault(import('../lk_broker/pages/account/registration/represes.vue' /* webpackChunkName: "pages/account/registration/represes" */))
const _c56155da = () => interopDefault(import('../lk_broker/pages/account/registration/steps/accepted.vue' /* webpackChunkName: "pages/account/registration/steps/accepted" */))
const _0a3831a8 = () => interopDefault(import('../lk_broker/pages/account/registration/steps/personal.vue' /* webpackChunkName: "pages/account/registration/steps/personal" */))
const _fc3345aa = () => interopDefault(import('../lk_broker/pages/account/registration/steps/reglament.vue' /* webpackChunkName: "pages/account/registration/steps/reglament" */))
const _2d8aa9c4 = () => interopDefault(import('../lk_broker/pages/acts/index.vue' /* webpackChunkName: "pages/acts/index" */))
const _063f9771 = () => interopDefault(import('../lk_broker/pages/agents/index.vue' /* webpackChunkName: "pages/agents/index" */))
const _90d676c8 = () => interopDefault(import('../lk_broker/pages/agents/index/_id.vue' /* webpackChunkName: "pages/agents/index/_id" */))
const _c48f4564 = () => interopDefault(import('../lk_broker/pages/apartment-catalog.vue' /* webpackChunkName: "pages/apartment-catalog" */))
const _3e0ae6a1 = () => interopDefault(import('../lk_broker/pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _6dc4fbcc = () => interopDefault(import('../lk_broker/pages/calendar/index/_id.vue' /* webpackChunkName: "pages/calendar/index/_id" */))
const _29172966 = () => interopDefault(import('../lk_broker/pages/choice-apartment.vue' /* webpackChunkName: "pages/choice-apartment" */))
const _7a46e92e = () => interopDefault(import('../lk_broker/pages/choice-apartment/index.vue' /* webpackChunkName: "pages/choice-apartment/index" */))
const _30bf8370 = () => interopDefault(import('../lk_broker/pages/choice-apartment/catalog.vue' /* webpackChunkName: "pages/choice-apartment/catalog" */))
const _33250dbc = () => interopDefault(import('../lk_broker/pages/choice-apartment/chessboard/index.vue' /* webpackChunkName: "pages/choice-apartment/chessboard/index" */))
const _a0bc948c = () => interopDefault(import('../lk_broker/pages/choice-apartment/chessboard/_project.vue' /* webpackChunkName: "pages/choice-apartment/chessboard/_project" */))
const _5dbcabda = () => interopDefault(import('../lk_broker/pages/choice-apartment/_project.vue' /* webpackChunkName: "pages/choice-apartment/_project" */))
const _835a9f4a = () => interopDefault(import('../lk_broker/pages/clients/index.vue' /* webpackChunkName: "pages/clients/index" */))
const _e5854ef4 = () => interopDefault(import('../lk_broker/pages/clients/index/_id.vue' /* webpackChunkName: "pages/clients/index/_id" */))
const _77b4971a = () => interopDefault(import('../lk_broker/pages/deals/index.vue' /* webpackChunkName: "pages/deals/index" */))
const _d0f2b2f0 = () => interopDefault(import('../lk_broker/pages/deals/index/_id/index.vue' /* webpackChunkName: "pages/deals/index/_id/index" */))
const _590649e2 = () => interopDefault(import('../lk_broker/pages/deals/index/_id/booking.vue' /* webpackChunkName: "pages/deals/index/_id/booking" */))
const _1459f30b = () => interopDefault(import('../lk_broker/pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _3383c336 = () => interopDefault(import('../lk_broker/pages/documents/index/_id.vue' /* webpackChunkName: "pages/documents/index/_id" */))
const _155f97e9 = () => interopDefault(import('../lk_broker/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _18c77c84 = () => interopDefault(import('../lk_broker/pages/fired.vue' /* webpackChunkName: "" */))
const _7a9d97e5 = () => interopDefault(import('../lk_broker/pages/interaction/index.vue' /* webpackChunkName: "pages/interaction/index" */))
const _0a4f55bf = () => interopDefault(import('../lk_broker/pages/knowbase/index.vue' /* webpackChunkName: "pages/knowbase/index" */))
const _9e80fb08 = () => interopDefault(import('../lk_broker/pages/login/index.vue' /* webpackChunkName: "" */))
const _172337e0 = () => interopDefault(import('../lk_broker/pages/loyalty-program/index.vue' /* webpackChunkName: "pages/loyalty-program/index" */))
const _cc2e3814 = () => interopDefault(import('../lk_broker/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _12c25ebc = () => interopDefault(import('../lk_broker/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _c52b2cac = () => interopDefault(import('../lk_broker/components/common/QuizSteps' /* webpackChunkName: "components/common/QuizSteps" */))
const _3a739396 = () => interopDefault(import('../lk_broker/pages/quiz.vue' /* webpackChunkName: "" */))
const _86250b68 = () => interopDefault(import('../lk_broker/pages/upload-documents.vue' /* webpackChunkName: "" */))
const _84661974 = () => interopDefault(import('../lk_broker/pages/errors/link-expired.vue' /* webpackChunkName: "" */))
const _575bf0bc = () => interopDefault(import('../lk_broker/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _20657cb1 = () => interopDefault(import('../lk_broker/pages/news/promo/_slug/index.vue' /* webpackChunkName: "pages/news/promo/_slug/index" */))
const _e37ce29e = () => interopDefault(import('../lk_broker/pages/news/_slug/index.vue' /* webpackChunkName: "pages/news/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '--active-link',
  linkExactActiveClass: '--exact-link',
  scrollBehavior,

  routes: [{
    path: "/account",
    components: {
      default: _2a6fd57d,
      aside: _4332012b
    },
    meta: {"hideFooter":true},
    name: "account",
    children: [{
      path: "/account/:role/change-password",
      component: _6b8569f4,
      meta: {"img":"new_pwd_image","hideFooter":true},
      name: "account-change-password"
    }, {
      path: "/account/:role/email-confirm",
      component: _5a138306,
      meta: {"img":"confirm_send_image","hideFooter":true},
      name: "account-email-confirm"
    }, {
      path: "/account/:role/email-confirmed",
      component: _4b1d7e5c,
      meta: {"img":"","hideFooter":true},
      name: "account-email-confirmed"
    }, {
      path: "/account/forgot-password",
      component: _6343231a,
      meta: {"img":"forgot_pwd_image","hideFooter":true},
      name: "account-forgot-password"
    }, {
      path: "/account/forgot-password-emailed",
      component: _52fd40e1,
      meta: {"img":"forgot_pwd_image","hideFooter":true},
      name: "forgot-password-emailed"
    }, {
      path: "/account/:role/reglament",
      component: _17634dd5,
      meta: {"img":"accept_contract_image","hideFooter":true},
      name: "account-reglament"
    }, {
      path: "/account/:role/set-password",
      component: _a6040ab0,
      meta: {"img":"new_pwd_image","hideFooter":true},
      name: "account-set-password"
    }, {
      path: "/account/agents/registration",
      component: _e83103dc,
      meta: {"img":"enter_agency_image","hideFooter":true},
      name: "account-registration-agents"
    }, {
      path: "/account/represes/registration",
      component: _bec9cb56,
      meta: {"img":"enter_agency_image","hideFooter":true},
      name: "account-registration-represes"
    }, {
      path: "/account/:role/registration/:id?/accepted",
      component: _c56155da,
      meta: {"img":"enter_personal_image","hideFooter":true},
      name: "account-registration-steps-accepted"
    }, {
      path: "/account/:role/registration/:id?/personal",
      component: _0a3831a8,
      meta: {"img":"enter_personal_image","hideFooter":true},
      name: "registration-personal"
    }, {
      path: "/account/:role/registration/:id?/reglament",
      component: _fc3345aa,
      meta: {"img":"accept_contract_image","hideFooter":true},
      name: "account-registration-steps-reglament"
    }, {
      path: "/account/agents/forgot-password",
      component: _6343231a,
      redirect: "/account/forgot-password",
      meta: {"img":"forgot_pwd_image","hideFooter":true},
      name: "account-forgot-password/account/agents/forgot-password"
    }, {
      path: "/account/represes/forgot-password",
      component: _6343231a,
      redirect: "/account/forgot-password",
      meta: {"img":"forgot_pwd_image","hideFooter":true},
      name: "account-forgot-password/account/represes/forgot-password"
    }]
  }, {
    path: "/acts",
    component: _2d8aa9c4,
    meta: {"disableScrollOnRouteChange":true},
    name: "acts"
  }, {
    path: "/agents",
    component: _063f9771,
    meta: {"disableScrollOnRouteChange":true},
    name: "agents",
    children: [{
      path: "/agents/:id",
      component: _90d676c8,
      meta: {"disableScrollOnRouteChange":true},
      name: "agents-id"
    }]
  }, {
    path: "/apartment-catalog",
    component: _c48f4564,
    name: "apartment-catalog"
  }, {
    path: "/calendar",
    component: _3e0ae6a1,
    meta: {"disableScrollOnRouteChange":true},
    name: "calendar",
    children: [{
      path: "/calendar/:type/:id",
      component: _6dc4fbcc,
      meta: {"disableScrollOnRouteChange":true},
      name: "calendar-id"
    }]
  }, {
    path: "/choice-apartment",
    component: _29172966,
    meta: {"disableScrollOnRouteChange":true},
    children: [{
      path: "",
      component: _7a46e92e,
      name: "choice-apartment"
    }, {
      path: "/choice-apartment/catalog",
      component: _30bf8370,
      meta: {"disableScrollOnRouteChange":true},
      name: "choice-apartment-catalog"
    }, {
      path: "/choice-apartment/chessboard",
      component: _33250dbc,
      name: "choice-apartment-chessboard"
    }, {
      path: "/choice-apartment/chessboard/:project",
      component: _a0bc948c,
      meta: {"disableScrollOnRouteChange":true},
      name: "choice-apartment-chessboard-project"
    }, {
      path: "/choice-apartment/:project",
      component: _5dbcabda,
      meta: {"disableScrollOnRouteChange":true},
      name: "choice-apartment-project"
    }]
  }, {
    path: "/clients",
    component: _835a9f4a,
    meta: {"disableScrollOnRouteChange":true},
    name: "clients",
    children: [{
      path: "/clients/:id",
      component: _e5854ef4,
      meta: {"disableScrollOnRouteChange":true},
      name: "clients-id"
    }]
  }, {
    path: "/deals",
    component: _77b4971a,
    meta: {"disableScrollOnRouteChange":true},
    name: "deals",
    children: [{
      path: "/deals/:id",
      component: _d0f2b2f0,
      meta: {"disableScrollOnRouteChange":true},
      name: "deals-id"
    }, {
      path: "/deals/:id/booking",
      component: _590649e2,
      meta: {"disableScrollOnRouteChange":true},
      name: "deals-id-booking"
    }]
  }, {
    path: "/documents",
    component: _1459f30b,
    meta: {"disableScrollOnRouteChange":true},
    name: "documents",
    children: [{
      path: "/documents/:title/:id",
      component: _3383c336,
      meta: {"disableScrollOnRouteChange":true},
      name: "documents-id"
    }]
  }, {
    path: "/faq",
    component: _155f97e9,
    name: "faq"
  }, {
    path: "/fired",
    components: {
      default: _18c77c84,
      aside: _4332012b
    },
    meta: {"img":"confirm_send_image","hideFooter":true},
    name: "fired"
  }, {
    path: "/interaction",
    component: _7a9d97e5,
    meta: {"disableScrollOnRouteChange":true},
    name: "interaction"
  }, {
    path: "/knowbase",
    component: _0a4f55bf,
    name: "knowbase"
  }, {
    path: "/login",
    components: {
      default: _9e80fb08,
      aside: _4332012b
    },
    meta: {"img":"confirm_send_image","hideFooter":true},
    name: "login"
  }, {
    path: "/loyalty-program",
    component: _172337e0,
    meta: {"disableScrollOnRouteChange":true},
    name: "loyalty-program"
  }, {
    path: "/news",
    component: _cc2e3814,
    meta: {"disableScrollOnRouteChange":true},
    name: "news"
  }, {
    path: "/profile",
    component: _12c25ebc,
    meta: {"disableScrollOnRouteChange":true},
    name: "profile"
  }, {
    path: "/deals/:id/quiz",
    components: {
      default: _3a739396,
      aside: _c52b2cac,
      mobileMenu: _c52b2cac
    },
    meta: {"hideFooter":false},
    name: "quiz"
  }, {
    path: "/deals/:id/upload-documents",
    components: {
      default: _86250b68,
      aside: _c52b2cac,
      mobileMenu: _c52b2cac
    },
    meta: {"hideFooter":false},
    name: "upload-documents"
  }, {
    path: "/errors/link-expired",
    components: {
      default: _84661974,
      aside: _4332012b
    },
    meta: {"img":"forgot_pwd_image","hideFooter":true},
    name: "linkExpired"
  }, {
    path: "/",
    component: _575bf0bc,
    name: "index"
  }, {
    path: "/news/promo/:slug",
    component: _20657cb1,
    name: "news-promo-slug"
  }, {
    path: "/news/:slug",
    component: _e37ce29e,
    name: "news-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
