// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hide_FFOw6,.show_N5Qa2{bottom:10rem;position:fixed;right:7rem;-webkit-transition-duration:.5s;transition-duration:.5s;-webkit-transition-property:opacity,visibility;transition-property:opacity,visibility;z-index:9}@media only screen and (max-width:767.98px){.hide_FFOw6,.show_N5Qa2{bottom:120px;right:26px}}.hide_FFOw6{opacity:0;visibility:hidden}.button_E8F6r{--s-button-custom-size:6.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hide": "hide_FFOw6",
	"show": "show_N5Qa2",
	"button": "button_E8F6r"
};
module.exports = ___CSS_LOADER_EXPORT___;
